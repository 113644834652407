import React from 'react'
import Privacypolicy from '../components/privacypolicy'

const PrivacyPolicy = () => {
  return (
    <div>
      <Privacypolicy />
    </div>
  )
}

export default PrivacyPolicy